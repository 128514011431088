<template>
  <div>

    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card>
      <!--搜索与添加区域-->
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入搜索内容" v-model="querInfo.r_name" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-button type="primary" @click="addRoleDialog" icon="el-icon-document-add" v-if="is_add">添加</el-button>
        </el-col>
      </el-row>

      <!--   用户列表区域   -->
      <el-table :data="rolelist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="角色名称" prop="r_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="创建时间" prop="add_date"></el-table-column>
        <el-table-column label="创建人" prop="add_uid"></el-table-column>
        <el-table-column label="操作" width="280px">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" size="mini" icon="el-icon-edit"
                       @click="roleEditDialog(scope.row.r_id)" v-if="is_edit">
            </el-button>
            <!--删除-->
            <el-button type="danger" size="mini" icon="el-icon-delete-solid"
                       @click="removeUserById(scope.row.r_id)" v-if="is_del">
            </el-button>
            <!--分配角色-->
            <el-tooltip effect="dark" content="分配权限" placement="top" :enterable="true" v-if="is_distribution">
              <el-button type="warning" size="mini" icon="el-icon-s-tools" @click="showSetRightDialog(scope.row)" ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <!--添加角色的对话框-->
    <el-dialog
        title="添加角色" @close="addRightDialoogClosed"
        :visible.sync="addRightDialogVisible"
        width="50%">
      <el-form ref="addWareFormRef" :model="AddRoleForm" label-width="100px"
               :rules="addRoleFormRules" >
        <el-form-item label="角色名称" prop="r_name">
          <el-input v-model="AddRoleForm.r_name"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addRightDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddallotRights" :loading="addloading">确 定</el-button>
      </span>
    </el-dialog>
    <!--编辑角色的对话框-->
    <el-dialog
        title="修改角色" @close="editRightDialoogClosed"
        :visible.sync="editRightDialogVisible"
        width="50%">
      <el-form ref="editWareFormRef" :model="editRoleForm" label-width="100px"
               :rules="editRoleFormRules" >
        <el-form-item label="角色名称" prop="r_name">
          <el-input v-model="editRoleForm.r_name"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editRightDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editallotRights" :loading="editloading">确 定</el-button>
      </span>
    </el-dialog>
    <!--分配权限的对话框-->
    <el-dialog
        title="分配权限" @close="setRightDialoogClosed"
        :visible.sync="setRightDialogVisible"
        width="50%">
      <!--树形控件-->
      <el-tree :data="rightlist" :props="defaultProps"
               node-key="m_id"  show-checkbox
               :default-checked-keys="defKeys" ref="treeRef">

      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRightDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="allotRights">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {

      addloading:false,
      editloading:false,
      querInfo: {
        page: 1,
        limit: 10,
        ql_name:''
      },
      total:0,
      //页面权限
      RoleJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      is_distribution:false,
      //表格加载
      loading:true,
      rolelist: [],
      //控制分配权限对话框的显示与隐藏
      setRightDialogVisible: false,
      //控制添加角色对话框的显示与隐藏
      addRightDialogVisible:false,
      //控制编辑角色对话框的显示与隐藏
      editRightDialogVisible:false,
      //所有权限的数据
      rightlist: [],
      //属性控件的绑定对象
      defaultProps:{
        label:'m_name',
        children:'children'
      },
      //默认选中的节点id数组
      defKeys:[],
      roleId:'',
      AddRoleForm:{
        r_name:''
      },
      addRoleFormRules:{
        r_name:[
          {required: true, message: '请输入角色名称', trigger: 'blur'}
        ],
      },
      editRoleFormRules:{
        r_name:[
          {required: true, message: '请输入角色名称', trigger: 'blur'}
        ],
      },
      editRoleForm:{}

    }
  },
  created() {
    this.getroleList()
    this.getmenuList()
    this.getRoleJurisdiction()
  },
  methods: {
    //获取权限接口
    async getRoleJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.RoleJurisdiction=res.data
      this.RoleJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }else if(item.m_path=='distribution'){
          this.is_distribution=true
        }
      })
    },
    //获取角色列表
    async getroleList() {
      this.loading=true
      const {data: res} = await this.$http.get('role/getList',{params: this.querInfo})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.rolelist = res.data.data
      this.total = res.data.total
      this.loading=false
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getroleList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getroleList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getroleList()
    },
    async getmenuList(){
      //获取所有权限的数据
      const {data: res} = await this.$http.get('menu/getLists')
      if (res.code != 200) return this.$message.error(res.msg)
      this.rightlist = res.data
    },
    //展示分配权限的对话框
    async showSetRightDialog(role) {
      this.roleId=role.r_id
      const {data: red} = await this.$http.get(`role/getMenu/${role.r_id}`)
      if (red.code != 200) return this.$message.error(red.msg)
      this.setRightDialogVisible = true
      this.defKey = red.data
      this.getNode()
    },
    getNode(){
      var that=this
      that.$nextTick(()=>{
        that.defKey.forEach((i,n) => {
          var node = this.$refs.treeRef.getNode(i);
          // console.log(node.isLeaf)
          if(node.isLeaf){
            this.$refs.treeRef.setChecked(node, true);
          }
        })
      })
    },

    //role/getMenu/1
    //监听分配权限对话框的关闭事件
    setRightDialoogClosed(){
      this.defKey=[]
      this.defKeys=[]
      this.$refs.treeRef.setCheckedKeys([])

    },
    //点击为角色分配权限
    async allotRights(){
      const keys=[
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys()
      ]
      const {data:res} = await this.$http.post('role/distribution',
          {'r_id': this.roleId , 'menulist': keys })
      if (res.code != 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.setRightDialogVisible=false
      this.getroleList()

    },
    //添加
    addRoleDialog(){
      this.addRightDialogVisible=true
    },
    //点击确定提交添加表单
    AddallotRights(){
      this.$refs.addWareFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('role/add', this.AddRoleForm)
        this.addloading=false
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.addRightDialogVisible = false
        this.getroleList()
      })
    },
    //监听添加对话框的关闭事件
    addRightDialoogClosed(){
      this.AddRoleForm={r_role:''}
      this.$refs.addWareFormRef.resetFields()
    },
    //监听编辑对话框的关闭事件
    editRightDialoogClosed(){
      this.editRoleForm={}
      this.$refs.editWareFormRef.resetFields()
    },

    //编辑
    async roleEditDialog(id){
      const {data:res} = await this.$http.get(`role/find/${id}`)
      if (res.code != 200)return this.$message.error(res.msg)
      this.editRoleForm=res.data
      this.editRightDialogVisible=true
    },
    //删除 role/del/1
    async removeUserById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除该角色, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`role/del/${id}`)
      if (res.code != 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.editRightDialogVisible=false
      this.getroleList()
    },
    //点击按钮提交编辑
    async editallotRights(){
      this.$refs.editRoleFormRef.validate(async valid => {
        if (!valid) return
        this.editloading=true
        const {data: res} = await this.$http.post('role/edit', this.editRoleForm)
        this.editloading=false
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.editRightDialogVisible = false
        this.getroleList()
      })
    }
  }
}
</script>
